import { Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
    return(
        <Layout pageTitle="About">
            <p>Sup?</p>
        </Layout>        
    )
}

export default AboutPage